@-webkit-keyframes color-change-3x {
    0% {
        border-color: #19ea89;
    }
    50% {
        border-color: #b22cff;
    }
    100% {
        border-color: #22b1ea;
    }
  }
  @keyframes color-change-3x {
    0% {
        border-color: #196a70;
    }
    50% {
        border-color: #17864f;
    }
    100% {
        border-color: #4b0e0e;
    }
  }
  

.sliderStyle{
    box-shadow: -4px 7px 12px 2px #7e7e7e96;
    border-radius: 10px;

}
.headerIconLogOut{
    padding: 7px;
    background: white;
    .logOutButton{
        display: flex;
        flex-direction: row;
        background: white;
        justify-content: space-between;
        width: 100%;
       .logoutButtonBorder{
            display: flex;
            align-items: center;
            button{
               background-color: transparent;
               height: fit-content;
               border: 3px solid #adaeb15c;
               padding: 8px 25px;
               border-radius: 10px;
               &:hover{
                   border: 3px solid #5555555c;
                   padding: 8px 24px;
                }
            }
        }
        .logoFitness{
            display: flex;
            background-color: transparent;
            width: 228px;
            height: 70px;
            img{
                width: 100%
            }
        }
    }
}
.homepage-layout {
    .homepageRow{
        display: flex;
        align-items: center;
        min-height: 75vh;
        background: white;
        border-radius: 5px;
    .logoImageFitness{
        .slider-styleImage{
            border-left: 5px solid #d1d1d1;
            border-left-style: dashed;
            background-color: transparent;
            padding: 20px;
            img{
                width: 100%;
                border-radius: 10px
            }
        }
    }
    .textButtons{
        display: flex;
        flex-direction: column;
        .fitness-name{
            border-bottom: 3px solid #cfdde7;
            font-size: 50px;
            margin: 0;
            width: 312px;
            letter-spacing: 0.05em;
            text-shadow: 4px 4px 0px #d5d5d5, 7px 7px 0px rgba(0, 0, 0, 0.2);
            
        }
        .fitness-nameCity{
            color: #8f8f8f;
            font-size: 28px;
        }
        .buttonsActionUsers{
            display: flex;
            justify-content: space-between;
                .buttonsActions{
                    .color-change-3x{
                        -webkit-animation: color-change-3x 4s linear infinite alternate both;
	                    animation: color-change-3x 4s linear infinite alternate both;
                        background-color: white;
                        height: fit-content;
                        padding: 7px 35px;
                        border: 1px solid #000;
                        border-left: 4px solid black;
                        border-radius: 15px;
                        &:hover{
                        border: 1px solid black;

                        }
                }
                }
                .buttonsActionsU{
                    display: contents;
                    .color-change-3x{
                        -webkit-animation: color-change-3x 4s linear infinite alternate both;
	                    animation: color-change-3x 4s linear infinite alternate both;
                        background-color: white;
                        height: fit-content;
                        padding: 7px 28px;
                        border: 1px solid #000;
                        border-left: 4px solid black;
                        border-radius: 15px;
                        &:hover{
                            border: 1px solid black;
                        }
                }
            
                }
            }
        }
.slider-style {
    border: 1px solid;
    min-width: 100%;
    max-width: 100%;
    min-height: 50vh;
    overflow: hidden;
}
    .slider-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        img {
            max-height: 50vh;
            min-height: 50vh;
            object-fit: cover;
        }
    } 

}

}
//ActUsers
.scanDevice{
    display: flex;
}
// 
.cardCode{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
padding: 5px 15px;
line-height: 1.2;
}
// 
.buttons {
    border-color: rgb(112, 112, 113);
    border-style: dashed;
    padding: 5px 10px;
    border-radius: 8px;
    color: black;
    font-weight: 600;
    background-color: transparent;
    &:hover{
  background-color: rgb(247, 247, 247);
  border-color: rgb(18, 18, 18);
  
    }
  }
  .searchButton{
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: #edf1f7;
  }
  .divNoUser{
border-bottom: 1px solid lightgray;
      .noUserFoundParagraf{
          text-align: center;
          font-size: 20px;
          color: gray;
        }
  }

.userCard{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    border-radius: 5px;
    .cardLayout{
        padding: 0px 2px 45px 0px;
        // display: flex;
        .divCardImage{
            padding: 20px;
            display: flex;
            justify-content: center;
            background-color: #337180;
            border-bottom-right-radius: 30px;
            .cardImage{
            
                img{
                    width: 130px;
                    height: 130px;
                    border-radius: 50%;
                }
            }
        }
        .nameUser{
            display: flex;
            justify-content: center;
            p{
                font-weight: 600;
                font-size: 18px;
            }
        }
        .deviceUserId{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 0px;
           
        }
    }
}

.buttonGroup{
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    gap: 6px;
    .buttonDropDown{
        background: none;
        color: black;
    }
    
}
.submitButton{
    .buttonSubmitLast{
    background-color:transparent;
    border: 2px solid green;
    color: green;
    font-weight: 600;
    border-radius: 20px;
    padding: 5px 20px;
    cursor: pointer;
    }
}
.backButton{
    display: flex;
    justify-content: end;
    button{
        border-radius: 21px;
        background: none;
        border: none;
        border: 2px solid #d2091c;
        padding: 6px 30px;
        font-weight: 600;
        box-shadow: -2px 2px 9px 1px rgb(163 163 163 / 83%);
        color: #d2091c;
        &:hover{
            background:#ecf0f3; 
            color: white;
            border: 2px solid #d2091c;
            transition: 0.3s;
            margin-right: 2px;
        }
    }
}
@media only screen and (max-width: 800px) {
    .headerIconLogOut .logOutButton .logoutButtonBorder button {
        font-size: 11px;
    }
    .submitButton{
        display: flex;
        justify-content: end;
        margin-top: 10px;
    }
    .backButton{
        button{
            padding: 4px 20px;
            border-radius: 21px;
        }
    }
}