.container_Body{
    margin-top: 13%;
    display: flex;
    justify-content: center;
}
.user_card {
    height: 400px;
    width: 350px;
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
}
.brand_logo_container {
    position: absolute;
    top: -100px;
    border-radius: 50%;
    text-align: center;
}
.brand_logo {
    height: 200px;
    width: 200px;
    border-radius: 50%;
}
.form_container {
    margin-top: 100px;
}
.login_btn {
    width: 100%;
    background: #c0392b !important;
    color: white !important;
}
.login_btn:focus {
    box-shadow: none !important;
    outline: 0px !important;
}
.login_container {
    padding: 0 2rem;
}
.input-group-text {
    background: transparent;
    color: #9d0e0e !important;
    border: 0 !important;
    border-radius: 0.25rem 0 0 0.25rem !important;
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;        
             i{ padding: 5px; }
}
.input_user .input_pass:focus {
    box-shadow: none !important;
    outline: 0px !important;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #c0392b !important;
}
.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
    margin-left: -1px;
    border: none;
    border-top-left-radius: 11;
    border-radius: 8px;
    background: transparent;
    border-radius: 4%;
}
.d-flex.justify-content-center.mt-3.login_container {
    border: 1px solid red;
    padding: 2%;
    border-radius: 7px;
}
.image_Container{ box-shadow: 0px 0px 12px 0px #bfbbbb;
    img{ height: 100vh; opacity: 0.6; }
}
@media only screen and (max-width: 800px) {
.image_Container{ display: none; }
.container_Body{ margin-top: 45%; }
}
.ol{ 
    border-radius: 8px;
    padding: 4px 5px;
    background-color: #f2f3f3bf;
    border: none;
}